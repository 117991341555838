import { graphql } from "gatsby";
import React from "react";
import { FavoritePageQuery, Ot } from "../../graphql-types";
import { usePageContext } from "../components/context/PageContext";
import * as styles from "../styles/pages/favorites.module.scss";
import BlockContent from "@sanity/block-content-to-react";
import { CardOt } from "../components/card/CardOt";

interface Props {
  data: FavoritePageQuery;
}

const FavoritesPage: React.FC<Props> = ({ data }) => {
  const { lang, favorites } = usePageContext();
  const { sanityFavoritePage } = data;

  return (
    <div className={styles.wrapper}>
      <header>
        <div className="desktopCentered">
          <h2>{sanityFavoritePage?.data?._rawTitle?.[lang]}</h2>
          <BlockContent
            blocks={sanityFavoritePage?.data?._rawDescription?.[lang]}
          />
        </div>
      </header>
      <section className={styles.favoritesWrapper}>
        <div
          className={styles.result}
        >{`${favorites.length} ${sanityFavoritePage?.data?._rawResultLabel?.[lang]}`}</div>
        <div className={styles.otsContainer}>
          {favorites.map(item => (
            <CardOt ot={item as Ot} />
          ))}
        </div>
      </section>
    </div>
  );
};

export const QUERY = graphql`
  query FavoritePage {
    sanityFavoritePage {
      data {
        _rawDeleteLabel
        _rawMoreLabel
        _rawResultLabel
        _rawDescription
        _rawTitle
      }
    }
  }
`;

export default FavoritesPage;
