import React from "react";
import { Critere, Ot } from "../../../graphql-types";
import ReactCountryFlag from "react-country-flag";
import * as styles from "./languages.module.scss";

interface Props {
  ot: Ot;
}

const supportedLanguages = [
  { countryCode: "gb", langue: "anglais" },
  { countryCode: "es", langue: "espagnol" },
];

const hasLanguage = (critere: Critere, language: string) =>
  critere?.valeurs?.find(
    item => item?.id === `LanguesParlees|${language.toUpperCase()}`
  );

export const Languages: React.FC<Props> = ({ ot }) => {
  const index = ot.criteres?.findIndex(item => item?.id === "LanguesParlees");
  if (!index || index === -1) return null;

  return (
    <div className={styles.wrapper}>
      {supportedLanguages.map(
        item =>
          hasLanguage(ot?.criteres?.[index] as Critere, item.langue) && (
            <ReactCountryFlag
              countryCode={item.countryCode}
              key={item.countryCode}
            />
          )
      )}
    </div>
  );
};
