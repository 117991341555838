import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-light-svg-icons";
import { faHeart as faSolidHeart } from "@fortawesome/pro-solid-svg-icons";
import * as styles from "./likeCard.module.scss";
import { usePageContext } from "../context/PageContext";

export interface LikeCardProps {
  big?: boolean;
  OT: any;
}

export const LikeCard: React.FC<LikeCardProps> = ({ big = false, OT }) => {
  const { favorites, toggleFavorite } = usePageContext();

  return (
    <div
      className={`${styles.mainContainer} ${big ? styles.big : ""}`}
      onClick={() => toggleFavorite(OT)}
    >
      {favorites &&
      favorites.filter((item: any) => item.id === OT.id).length ? (
        <FontAwesomeIcon icon={faSolidHeart} />
      ) : (
        <FontAwesomeIcon icon={faHeart} />
      )}
    </div>
  );
};
