import React from "react";
import {
  faSnowflake,
  faWheelchair,
  faWifi,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Critere, Ot } from "../../../graphql-types";
import * as styles from "./logos.module.scss";

interface Props {
  ot: Ot;
  big?: boolean;
}

export const Logos: React.FC<Props> = ({ ot, big = false }) => (
  <div className={`${styles.wrapper} ${big ? styles.big : ""}`}>
    {hasPmr(ot?.criteres as Critere[]) && (
      <FontAwesomeIcon icon={faWheelchair} />
    )}
    {hasClim(ot?.criteres as Critere[]) && (
      <FontAwesomeIcon icon={faSnowflake} />
    )}
    {hasWifi(ot?.criteres as Critere[]) && <FontAwesomeIcon icon={faWifi} />}
  </div>
);

const hasWifi = (criteres: Critere[]) => {
  const index = criteres?.findIndex(
    item => item.id === "PrestationsEquipements"
  );
  if (index !== -1)
    return criteres?.[index]?.valeurs?.find(
      item => item?.id === "PrestationsEquipements|WIFI"
    );
  return false;
};

const hasClim = (criteres: Critere[]) => {
  const index = criteres.findIndex(
    item => item.id === "PrestationsEquipements"
  );
  if (index !== -1)
    return criteres?.[index]?.valeurs?.find(
      item => item?.id === "PrestationsEquipements|CLIMATISATION"
    );
  return false;
};

const hasPmr = (criteres: Critere[]) => {
  const index = criteres.findIndex(item => item.id === "AccesPMR");
  if (index !== -1)
    return criteres?.[index]?.valeurs?.[0]?.id === "AccesPMR|OUI"
      ? true
      : false;
  return false;
};
