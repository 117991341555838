import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Ot, DiscoverButtonQuery } from "../../../graphql-types";
import { slugify } from "../../utils/slugify";
import { usePageContext } from "../context/PageContext";
import { Languages } from "../criteres/Languages";
import { Logos } from "../criteres/Logos";
import { LinkButtonColor, StyledButton } from "../styled/StyledButton";
import { LikeCard } from "./LikeCard";
import * as styles from "./cardOt.module.scss";
import { StyledLink } from "../styled/StyledLink";

interface Props {
  ot: Ot;
  pathSelection?: string;
}

export const CardOt: React.FC<Props> = ({ ot, pathSelection = "/event" }) => {
  const { lang } = usePageContext();
  const { sanitySettings } = useStaticQuery<DiscoverButtonQuery>(QUERY);
  const thumbnail = ot?.medias?.[0]?.resolutions?.find(
    (resolution: any) => resolution?.type === "320x240"
  )?.url;

  return (
    <article className={styles.wrapper}>
      <div className={styles.likeCard}>
        <LikeCard OT={ot} big />
      </div>

      <StyledLink path={`${pathSelection}/${slugify(ot.libelle?.fr ?? "")}`}>
        <img alt={ot.libelle?.[lang] ?? ""} src={thumbnail ?? ""} />
        <div className={styles.content}>
          <div className={styles.logosWrapper}>
            <Logos ot={ot as Ot} />
            <Languages ot={ot as Ot} />
          </div>
          <div className={styles.text}>
            <div className={styles.fade} />
            <h2>{ot.libelle?.[lang]}</h2>
            {ot.descriptifs && (
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: ot.descriptifs[0]?.contenu?.[lang] ?? "",
                }}
              />
            )}
          </div>
          <StyledButton
            onClick={() => {}}
            label={sanitySettings?.main?._rawDiscoverButton?.[lang]}
            color={LinkButtonColor.black}
            className={styles.button}
          />
        </div>
      </StyledLink>
    </article>
  );
};

const QUERY = graphql`
  query DiscoverButton {
    sanitySettings {
      main {
        _rawDiscoverButton
      }
    }
  }
`;
